import React, { useEffect } from 'react';
import Login from 'core/views/LoginRequest';
import { useSelector, useDispatch } from 'react-redux';
import { goBack } from 'react-router-redux';
import { isLoggedInSelector } from 'core/modules/auth/selectors';


const LoginView = () => {
  const isLoggedIn = useSelector(isLoggedInSelector),
        dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(goBack());
    }
  }, [dispatch, isLoggedIn]);

  return <Login />;
};

export default LoginView;
