import React from 'react';
import {
  Router, Route, IndexRoute, browserHistory,
} from 'react-router';
import { Manager, Placeholder } from 'react-as-promised';
import { withTheme } from 'core/contexts/theme';
import { syncHistoryWithStore } from 'react-router-redux';
import CoreApp from 'core/App';
import TimingSettings from 'core/views/TimingSettings';

import Login from './views/Profile/Login';
import { Provider as ThemeProvider } from './contexts/theme-material';
import SnackbarPlaceholder from './views/SnackbarPlaceholder';
import App from './App';
import TodoListActive from './views/TodoListActive';
import TodoListPostponed from './views/TodoListPostponed';
import TodoListDone from './views/TodoListDone';
import TodoListDeleted from './views/TodoListDeleted';
import Profile from './views/Profile';
import PostponeModal from './views/components/PostponeModal';
import ThemeSelector from './views/ThemeSelector';
import store from './store';


Manager.registerComponent('PostponeModal', PostponeModal, 'onSelectPostponed', 'onClosed');

const history = syncHistoryWithStore(browserHistory, store);

const Wrapper = withTheme(({ children, theme }) => (
  <div style={{ flex: 1, backgroundColor: theme.colors.background }}>{children}</div>
));

const Lyster = () => (
  <CoreApp store={store}>
    <ThemeProvider>
      <Wrapper>
        <SnackbarPlaceholder />
        <Placeholder />
        <Router history={history}>
          <Route path='/' component={App}>
            <IndexRoute component={TodoListActive} />
            <Route path='todo' component={TodoListActive} />
            <Route path='later' component={TodoListPostponed} />
            <Route path='profile'>
              <IndexRoute component={Profile} />
              <Route path='login' component={Login} />
              <Route path='done' component={TodoListDone} />
              <Route path='deleted' component={TodoListDeleted} />
              <Route path='themes' component={ThemeSelector} />
              <Route path='timing' component={TimingSettings} />
            </Route>
          </Route>
        </Router>
      </Wrapper>
    </ThemeProvider>
  </CoreApp>
);

export default Lyster;
