/* @flow */

import React, { PureComponent } from 'react';
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import InfiniteCalendar from 'react-infinite-calendar';
import { detectDeviceClockFormat } from 'core/utils/localization';
import dayjs from 'dayjs';
import dayjsUtils from '@date-io/dayjs';
import { withTheme } from 'core/contexts/theme';


type Props = {
  currentDate: Date,
  minimumDate: Date,
  onUpdateDate: (date: Date) => void,
  theme: ThemeContextData,
}

type State = {
  selectedDate: Date
}

const styles = {
  container: { backgroundColor: 'white', alignItems: 'center' },
};


class DatePicker extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { currentDate } = props;

    this.state = {
      selectedDate: currentDate,
    };
  }

  UNSAFE_componentWillReceiveProps(props: Props) {
    const { currentDate } = this.props;

    if (props.currentDate !== currentDate) {
      this.setState({ selectedDate: props.currentDate });
    }
  }

  onUpdateDate = (date: Date) => {
    const { onUpdateDate } = this.props,
          { selectedDate } = this.state;

    const diff = dayjs(selectedDate).diff(dayjs(selectedDate).startOf('day')),
          newSelectedDate = dayjs(date).add(diff, 'ms').toDate();

    this.setState({ selectedDate: newSelectedDate });
    onUpdateDate(newSelectedDate);
  }

  onUpdateTime = (date: dayjs) => {
    const { onUpdateDate } = this.props;

    this.setState({ selectedDate: date.toDate() });
    onUpdateDate(date.toDate());
  }

  render() {
    const { selectedDate } = this.state,
          { minimumDate, theme } = this.props;

    const calendarTheme = {
      accentColor: theme.colors.primary,
      floatingNav: {
        background: 'rgb(150, 150, 150)',
        chevron: '#FFA726',
        color: '#FFF',
      },
      headerColor: theme.colors.primary,
      selectionColor: theme.colors.primary,
      textColor: {
        active: '#FFF',
        default: '#333',
      },
      todayColor: '#FFA726',
      weekdayColor: 'black',
    };

    return (
      <div style={styles.container}>
        <MuiPickersUtilsProvider utils={dayjsUtils}>
          <InfiniteCalendar
            width={300}
            height={200}
            selected={selectedDate}
            minDate={minimumDate}
            theme={calendarTheme}
            onSelect={this.onUpdateDate}
          />
          <div style={{
            flex: 1, display: 'flex', justifyContent: 'center', backgroundColor: theme.colors.surface, paddingTop: 20,
          }}
          >
            <TimePicker
              clearable
              ampm={detectDeviceClockFormat() !== '24'}
              value={selectedDate}
              minutesStep={5}
              onChange={this.onUpdateTime}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default withTheme(DatePicker);
