/* @flow */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View } from 'react-native';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import { isLoggedInSelector } from 'core/modules/auth/selectors';
import l from 'core/utils/localization';
import { push } from 'react-router-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import bugsnagClient from 'core/clients/bugsnag-client';
import UserFeedback from 'core/utils/errors/UserFeedback';

import ListBackgroundImage from '../components/ListBackgroundImage';
import ProfileView from '../components/ProfileView';


const styles = {
  container: { height: '100%', alignItems: 'center', padding: 20 },
  listWrapper: { maxWidth: 500, alignSelf: 'center', margin: 5 },
  paper: {
    padding: 20, margin: 20, alignItems: 'center', justifyContent: 'center', flex: 1,
  },
  flexOne: { flex: 1 },
  button: { alignSelf: 'center', marginTop: 10 },
  maxWidth: { maxWidth: 400 },
  thirdPaper: { maxWidth: 400, marginTop: 20 },
};

const Profile = () => {
  const isLoggedIn = useSelector(isLoggedInSelector),
        dispatch = useDispatch(),
        onClickLogin = () => dispatch(push('/profile/login')),
        onClickDone = () => dispatch(push('/profile/done')),
        onClickDeleted = () => dispatch(push('/profile/deleted')),
        onClickThemes = () => dispatch(push('/profile/themes')),
        onClickTimingSettings = () => dispatch(push('/profile/timing')),
        onClickFeedback = () => {
          const text = prompt(l.whatCanWeDoBetterPleaseLetUsKnow);
          if (text) {
            bugsnagClient.notify(new UserFeedback(text));
          }
        };

  return (
    <View style={styles.container}>
      <ListBackgroundImage />
      { isLoggedIn ? <ProfileView /> : (
        <Paper style={styles.paper}>
          <Typography>{ l.loginToSync }</Typography>
          <View style={styles.flexOne}>
            <Button
              variant='contained'
              color='primary'
              style={styles.button}
              onClick={onClickLogin}
            >
              { l.loginNow }
            </Button>
          </View>

        </Paper>
      ) }
      <Paper style={styles.maxWidth}>
        <List style={styles.listWrapper}>
          <ListItem
            button
            color='primary'
            onClick={onClickDone}
          >
            <Typography>{l.listname_done}</Typography>
          </ListItem>


          <ListItem
            button
            color='primary'
            onClick={onClickDeleted}
          >
            <Typography>{l.listname_deleted}</Typography>
          </ListItem>
        </List>
      </Paper>
      <Paper style={styles.thirdPaper}>
        <List>
          <ListItem
            button
            color='primary'
            onClick={onClickThemes}
          >
            <Typography>{l.themeSelector}</Typography>
          </ListItem>
          <ListItem
            button
            color='primary'
            onClick={onClickTimingSettings}
          >
            <Typography>{l.timingSettings}</Typography>
          </ListItem>
          <ListItem
            button
            color='primary'
            onClick={onClickFeedback}
          >
            <Typography>{l.leaveFeedback}</Typography>
          </ListItem>
        </List>
      </Paper>
    </View>
  );
};

export default Profile;
