/* @flow */

import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import BaseTheme from 'core/contexts/theme';


const getTheme = (baseTheme) => {
  const { theme: { colors, isDark } } = baseTheme;

  return createMuiTheme({
    /* fontFamily: 'Roboto, sans-serif',
    borderRadius: 2, */

    palette: {
      type: isDark ? 'dark' : 'light',
      primary: { main: colors.primary },
      secondary: { main: colors.secondary },
      error: { main: colors.delete },
      text: {
        primary: colors.text,
        // secondary: string;
        // disabled: string;
        // hint: string;
      },
      // action,
      // Used by `getContrastText()` to maximize the contrast between the background and
      // the text.
      contrastThreshold: 3,
      // Used to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
      divider: colors.separator,
      background: { default: colors.background, paper: colors.surface },
    },
    overrides: {
      Checkbox: {
        color: colors.primaryText,
      },
      /* MuiButton: {
        root: {
          color: colors.primaryText,
          label: {
            color: colors.primaryText,
          },
        },
        label: {
          color: colors.primaryText,
        },
      }, */
    },
  });
};

export const Provider = ({ children }) => {
  const baseTheme = useContext(BaseTheme);

  return (
    <MuiThemeProvider theme={getTheme(baseTheme)}>
      {children}
    </MuiThemeProvider>
  );
};

export default { Provider };
