/* @flow */
import React, { useContext } from 'react';
import TodoListPostponed from 'core/views/TodoListPostponed';
import MultiSelectContext, { withMultiSelectProvider } from 'core/contexts/MultiSelect';


export default withMultiSelectProvider(
  (props) => {
    const { isEnabled } = useContext(MultiSelectContext),
          headerComponentOffset = isEnabled ? 154 + 56 : 154;

    return <TodoListPostponed {...props} headerComponentOffset={headerComponentOffset} />;
  },
);
