/* @flow */

import React, { PureComponent } from 'react';
import { View } from 'react-native';
import { connect } from 'react-redux';
import Icon from 'react-native-vector-icons/FontAwesome';
import dayjs from 'dayjs';
import Promise from 'bluebird';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { withTheme } from 'core/contexts/theme';
import { logout } from 'core/modules/auth/actions';
import {
  userEmailSelector,
  userNeedsToReauthenticateBySelector,
} from 'core/modules/auth/selectors';
import l from 'core/utils/localization';
import SyncIndicator from 'core/components/SyncIndicator';


type Props = {
  dispatch: (any) => any,
  userEmail: string,
  theme: ThemeContextData
};

const styles = {
  container: {
    alignItems: 'center',
    padding: 20,
    marginBottom: 20,
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  centeredText: { textAlign: 'center', color: 'white' },
  userIconContainer: {
    width: 80,
    height: 80,
    margin: 10,
    backgroundColor: 'lightgray',
    borderRadius: 50,
    justifyContent: 'center',
  },
  userIcon: { textAlign: 'center', alignSelf: 'center' },
  emailText: {
    textAlign: 'center', margin: 5, flex: 1, fontSize: 20,
  },
  signOutButtonContainer: { flex: 1, padding: 15 },
};

class ProfileView extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.updateEveryMinute();
  }

  UNSAFE_componentWillReceiveProps() {
    this.updateEveryMinute();
  }

  updateEveryMinute = () => {
    if (this.renderEveryMinute) {
      this.renderEveryMinute.cancel();
    }
    const ms = dayjs().add(1, 'minute').startOf('minute').valueOf() - dayjs().valueOf();
    this.renderEveryMinute = Promise.delay(ms).then(() => {
      this.forceUpdate();
      this.updateEveryMinute();
    });
  }

  renderEveryMinute: Promise<any>

  onLogout = () => {
    const { numberOfItemsWaitingToSync, dispatch } = this.props;
    if (numberOfItemsWaitingToSync > 0) {
      const result = window.confirm(`${l.areYouSure}\n${l.youHaveUnsyncedChangesThatWillBeLost}`);
      if (result) {
        dispatch(logout());
      }
    } else {
      dispatch(logout());
    }
  }

  props: Props;

  render() {
    const {
      userEmail,
      theme,
    } = this.props;

    return (
      <Paper style={styles.container}>
        <SyncIndicator />

        <View style={styles.userIconContainer}>
          <Icon
            name='user'
            size={30}
            style={styles.userIcon}
            color='white'
          />
        </View>
        <Typography style={styles.emailText}>{userEmail}</Typography>
        <Button
          variant='contained'
          color='primary'
          onClick={this.onLogout}
          style={styles.signOutButtonContainer}
        >
          <Typography color={theme.colors.primaryText}>
            {` ${l.signOut} `}
            <Icon name='sign-out' size={14} color={theme.colors.primaryText} />
          </Typography>
        </Button>
      </Paper>
    );
  }
}

export default connect(
  (state) => ({
    userEmail: userEmailSelector(state),
    userNeedsToReauthenticateBy: userNeedsToReauthenticateBySelector(state),
  }),
)(withTheme(ProfileView));
