import storeCreator from 'core/store-creator';
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import auth from 'core/modules/auth/reducer';
import todo from 'core/modules/todo/reducer';
import system from 'core/modules/system/reducer';
import network from 'core/modules/network/reducer';
import calendar from 'core/modules/calendar/reducer';
import settings from 'core/modules/settings/reducer';


const reducers = combineReducers({
  auth,
  todo,
  system,
  calendar,
  routing: routerReducer,
  network,
  settings,
});


const store = storeCreator(reducers);

export default store;
