/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 * @flow
 */

import React, { useEffect, useState } from 'react';
import { push } from 'react-router-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import { useSelector, useDispatch } from 'react-redux';
import { isLoggedInSelector } from 'core/modules/auth/selectors';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {
  numberOfItemsWaitingToSyncSelector,
  activeTodoCountSelector,
} from 'core/modules/todo/selectors';
import useUrlActions from 'core/hooks/useUrlActions';
import l from 'core/utils/localization';
import { useTheme } from 'core/contexts/theme';
import { isConnectedSelector } from 'core/modules/system/selectors';


import TodoItemDetail from './views/TodoItemDetail';


const styles = {
  container: {
    flex: 1,
    height: '100%',
  },
  tab: { maxWidth: '25%', width: 200 },
};

const App = ({ children, location }) => {
  const
        activeTodoCount = useSelector(activeTodoCountSelector),
        numberOfItemsWaitingToSync = useSelector(numberOfItemsWaitingToSyncSelector),
        isLoggedIn = useSelector(isLoggedInSelector),
        urlActions = useUrlActions(),
        theme = useTheme(),
        dispatch = useDispatch(),
        [selectedIndex, setSelectedIndex] = useState(1),
        isConnected = useSelector(isConnectedSelector);

  useEffect(() => {
    document.title = activeTodoCount > 0 ? `(${activeTodoCount}) Lyster App` : 'Lyster App';
  }, [activeTodoCount]);

  onbeforeunload = (e) => {
    if (numberOfItemsWaitingToSync > 0 && isLoggedIn && isConnected) {
      const message = 'Not all changes have been synced - are you sure?',
            event = e || window.event;

      // For IE and Firefox
      if (event) {
        event.returnValue = message;
      }

      // For Safari
      return message;
    }
    return undefined;
  };

  useEffect(() => {
    window.onbeforeunload = onbeforeunload;

    urlActions(window.document.location.href);

    const { pathname } = location,
          initialIndex = pathname.startsWith('/later')
            ? 0
            : pathname.startsWith('/todo') || pathname === '/'
              ? 1
              : 2;

    setSelectedIndex(initialIndex);
  }, []); // eslint-disable-line

  return (
    <div style={styles.container}>
      <TodoItemDetail />
      <Tabs
        style={{
          zIndex: 1000,
          position: 'fixed',
          top: 0,
          right: 0,
          left: 0,
          backgroundColor: theme.colors.primary,
          color: theme.colors.primaryText,
          boxShadow: '1px 2px 4px rgba(0, 0, 0, .5)',
        }}
        onChange={(evt, index) => {
          setSelectedIndex(index);
          const goto = index === 0 ? '/later' : index === 1 ? '/todo' : '/profile';

          dispatch(push(goto));
        }}
        value={selectedIndex}
        centered
      >
        <Tab
          value={0}
          label={l.listname_postponed}
          style={styles.tab}
          icon={(
            <Icon
              name='access-time'
              color={theme.colors.primaryText}
            />
          )}
        />
        <Tab
          value={1}
          icon={(
            <Icon
              name='list'
              color={theme.colors.primaryText}
            />
          )}
          style={styles.tab}
          label={(
            <Badge
              badgeContent={activeTodoCount}
              color='secondary'
            >
              {l.listname_todo}
            </Badge>
          )}
        />
        <Tab
          value={2}
          label={l.profile}
          style={styles.tab}
          icon={(
            <Icon
              name='person'
              color={theme.colors.primaryText}
            />
          )}
        />
      </Tabs>
      <div style={{ paddingTop: 72 }}>
        {children}
      </div>
    </div>
  );
};

export default App;
