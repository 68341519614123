/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { dismissClipboard } from 'core/modules/system/actions';
import {
  shouldShowClipboardSelector,
  clipboardContentsSelector,
} from 'core/modules/system/selectors';
import {
  undoValidUntilSelector,
  undoTextSelector,
} from 'core/modules/todo/selectors';
import {
  undoLastAction,
} from 'core/modules/todo/actions';
import { withTheme } from 'core/contexts/theme';
import l from 'core/utils/localization';


/* eslint-disable react/no-unused-prop-types */
type Props = {
  undoValidUntil: Date,
  shouldShowClipboard: boolean,
  clipboardContents: string,
  undoText: string,
  undoLastAction: () => void,
  dismissClipboard: () => void,
  theme: ThemeContextData
}
/* eslint-enable react/no-unused-prop-types */

type State = {
  isOpen: boolean,
  message: string,
  autoHideDuration: number,
  actionText: string,
  actionHandler: *
}

const updateWithProps = (newProps: Props) => {
  const validUndoableForAnother = newProps.undoValidUntil - new Date();
  if (validUndoableForAnother > 0) {
    return {
      isOpen: true,
      actionText: l.undo,
      message: newProps.undoText,
      autoHideDuration: validUndoableForAnother,
      actionHandler: newProps.undoLastAction,
    };
  } if (newProps.shouldShowClipboard) {
    return {
      isOpen: true,
      actionText: l.add,
      message: newProps.undoText,
      autoHideDuration: 10000,
      actionHandler: newProps.dismissClipboard,
    };
  }
  return {
    isOpen: false,
  };
};

class SnackbarPlaceholder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      actionText: '',
      message: '',
      autoHideDuration: 0,
      actionHandler: () => {},
      ...updateWithProps(props),
    };
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    this.setState(updateWithProps(newProps));
  }

  handleRequestClose = () => {
    this.setState(updateWithProps(this.props));
  }

  render() {
    const {
      isOpen, message, actionText, autoHideDuration, actionHandler,
    } = this.state;

    return (
      <Snackbar
        open={isOpen}
        message={message}
        action={(
          <Button
            style={{ cursor: 'pointer' }}
            color='primary'
            onClick={actionHandler}
          >
            { actionText }
          </Button>
        )}
        autoHideDuration={autoHideDuration}
        onClose={this.handleRequestClose}
      />
    );
  }
}

export default connect((state) => ({
  undoValidUntil: undoValidUntilSelector(state),
  shouldShowClipboard: shouldShowClipboardSelector(state),
  clipboardContents: clipboardContentsSelector(state),
  undoText: undoTextSelector(state),
}), {
  undoLastAction,
  dismissClipboard,
})(withTheme(SnackbarPlaceholder));
