/* @flow */
import React, { useContext } from 'react';
import TodoListDeleted from 'core/views/TodoListDeleted';
import MultiSelectContext, { withMultiSelectProvider } from 'core/contexts/MultiSelect';


export default withMultiSelectProvider(
  (props) => {
    const { isEnabled } = useContext(MultiSelectContext),
          headerComponentOffset = isEnabled ? 72 + 56 : 72;

    return <TodoListDeleted {...props} headerComponentOffset={headerComponentOffset} />;
  },
);
