import 'babel-polyfill';
import { AppRegistry } from 'react-native';
import { browserHistory } from 'react-router';
import Promise from 'promise-polyfill';

import 'core/config';
import './load-assets';


if (!window.Promise) {
  window.Promise = Promise;
}

if (!''.startsWith) {
  require('string.prototype.startswith');
}

// Sets up offline caching for all assets (disabled by default)
// You can enable offline caching by changing
// `enableOfflinePlugin` at the top of web/webpack.config.js

if (__OFFLINE__) {
  const OfflinePluginRuntime = require('offline-plugin/runtime');
  OfflinePluginRuntime.install({
    onUpdateReady: () => {
      OfflinePluginRuntime.applyUpdate();
    },
    onUpdated: () => {
      window.swUpdate = true;
    },
  });

  browserHistory.listen((location) => {
    if (window.swUpdate) {
      const newLocation = location.pathname + location.search + location.hash;
      window.location = newLocation;
    }
  });
}

AppRegistry.registerComponent('Lyster', () => require('./src').default);
AppRegistry.runApplication('Lyster', {
  rootTag: window.document.getElementById('react-root'),
});
