/* @flow */

import React, { useContext } from 'react';
import TodoListActive from 'core/views/TodoListActive';
import MultiSelectContext, { withMultiSelectProvider } from 'core/contexts/MultiSelect';


export default withMultiSelectProvider(
  (props) => {
    const { isEnabled } = useContext(MultiSelectContext),
          headerComponentOffset = isEnabled ? 72 + 56 : 72;

    return <TodoListActive {...props} headerComponentOffset={headerComponentOffset} />;
  },
);
