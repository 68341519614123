// generate required css
import materialIcons from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import materialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import fontAwesomeIcons from 'react-native-vector-icons/Fonts/FontAwesome.ttf';

import 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css'; // only needs to be imported once
import './src/assets/style.css';


const importFontface = (styles) => {
  // create stylesheet
  const style = document.createElement('style');
  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = styles;
  } else {
    style.appendChild(document.createTextNode(styles));
  }

  // inject stylesheet
  document.head.appendChild(style);
};

const generateFontFace = (fontFile, fontFamilyName) => {
  importFontface(`@font-face { src:url(${fontFile});font-family: "${fontFamilyName}"; }`);
};

generateFontFace(materialIcons, 'MaterialIcons');
generateFontFace(materialCommunityIcons, 'MaterialCommunityIcons');
generateFontFace(fontAwesomeIcons, 'FontAwesome');
