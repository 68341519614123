/* @flow */

import React, { Component } from 'react';
import Promise from 'bluebird';
import { View } from 'react-native';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import { updateTodo, createTodo } from 'core/modules/todo/actions';
import { closeItemDetails } from 'core/modules/navigation/actions.web';
import { openItemSelector } from 'core/modules/todo/selectors';
import l from 'core/utils/localization';
import dayjs from 'dayjs';


type Props = {
  dispatch: Dispatch,
  item: Object,
  isVisible: boolean,
  postponedUntil: Date,
  status: Status
}

const styles = {
  buttonContainer: {
    flexDirection: 'row', flex: 1, justifyContent: 'space-between', paddingTop: 10,
  },
};

function getCaret(el) {
  if (el.selectionStart) {
    return el.selectionStart;
  } if (document.selection) {
    const r = document.selection.createRange();
    if (r == null) {
      return 0;
    }
    const re = el.createTextRange(),
          rc = re.duplicate();
    re.moveToBookmark(r.getBookmark());
    rc.setEndPoint('EndToStart', re);
    return rc.text.length;
  }
  return 0;
}

function setCaretPosition(elem, caretPos) {
  if (elem != null) {
    if (elem.createTextRange) {
      const range = elem.createTextRange();
      range.move('character', caretPos);
      range.select();
    } else if (elem.selectionStart) {
      elem.focus();
      elem.setSelectionRange(caretPos, caretPos);
    } else { elem.focus(); }
  }
}

type State = {
  id: ?string,
  title: string,
  recurring: ?RecurringMetadataProps,
}

class TodoItemDetail extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    if (props.isVisible) {
      this.focus = Promise.delay(0).then(() => this.txtTitle.focus());
    }

    this.state = {
      id: undefined,
      title: '',
      recurring: undefined,
    };
  }

  focus: Promise<any>

  handleSave = () => {
    const { item, status, postponedUntil } = this.props,
          { title, recurring } = this.state,
          hasChanged = !item || title !== item.title || recurring !== item.recurring;

    if (title && title.length > 0 && hasChanged) {
      const { dispatch } = this.props;

      dispatch(item ? updateTodo(item, {
        title,
        recurring,
      }) : createTodo({
        title,
        status,
        recurring,
        postponedUntil: postponedUntil
          ? dayjs(postponedUntil)
          : undefined,
      }));
    }

    this.handleClose();
    return Promise.resolve();
  }

  handleClose = () => {
    const { dispatch } = this.props;

    dispatch(closeItemDetails());

    return Promise.delay(0).then(() => {
      this.setState({ title: '' });
    });
  }

  onTitleKeyDown = (event) => {
    if (event.keyCode === 13) {
      const content = this.txtTitle.value,
            element = this.txtTitle,
            caret = getCaret(element);

      if (event.shiftKey) {
        this.setState({
          title: `${content.substring(0, caret)}\n${content.substring(caret, content.length)}`,
        });
        // eslint-disable-next-line promise/catch-or-return
        Promise.delay(0).then(() => {
          setCaretPosition(element, caret + 1);
        });
      } else {
        this.handleSave();
      }
      event.stopPropagation();
      event.preventDefault();
      return false;
    }
    return true;
  }

  onChangeText = (evt) => {
    const title = evt.currentTarget.value;
    this.setState({ title });
  }

  UNSAFE_componentWillReceiveProps(newProps: Props) {
    const { item, isVisible } = this.props;

    if (newProps.item !== item) {
      if (!newProps.item) {
        this.setState({ title: '', recurring: undefined });
      } else if (!item || newProps.item.id !== item.id) {
        const { title, recurring } = newProps.item;
        this.setState({ title, recurring });
      }
    }

    if (newProps.isVisible && !isVisible) {
      this.focus && this.focus.cancel();
      this.focus = Promise.delay(0).then(() => this.txtTitle.focus());
    }

    return this.focus;
  }

  txtTitle: any

  render() {
    const { isVisible } = this.props,
          { title } = this.state;

    return (
      <Dialog
        open={isVisible}
        onClose={this.handleClose}
        fullWidth
      >
        <View style={{
          padding: 10, flex: 1, width: '100%', maxWidth: 600,
        }}
        >
          <View>
            <TextField
              multiline
              autoFocus
              // rows={2}
              // rowsMax={10}
              style={{ fontSize: 30 }}
              inputRef={(ref) => {
                this.txtTitle = ref;
              }}
              onChange={this.onChangeText}
              onKeyDown={this.onTitleKeyDown}
              value={title}
            />
          </View>
          <View style={styles.buttonContainer}>
            <Button
              variant='text'
              onClick={this.handleClose}
            >
              {l.cancel}
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={this.handleSave}
            >
              {l.save}
            </Button>
          </View>
        </View>
      </Dialog>
    );
  }
}

export default connect((state) => ({
  item: openItemSelector(state),
  isVisible: !!state.routing.locationBeforeTransitions.query.itemId,
  status: state.routing.locationBeforeTransitions.query.status,
  postponedUntil: state.routing.locationBeforeTransitions.query.postponedUntil,
}))(TodoItemDetail);
